export const FIRESTONE = "firestone"
export const HIBDON_TIRES_PLUS = "hibdon_tires_plus"
export const MICHEL_TIRES_PLUS = "michel_tires_plus"
export const TIRES_PLUS = "tires_plus"
export const WHEEL_WORKS = "wheel_works"

export const JIFFY_LUBE_SHOP = "JiffyLube"
export const VALVOLINE_SHOP = "Valvoline"
export const TAKE_5_SHOP = "Take 5"

export const PAGE_FAVORITES = "favorites"
